.footer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: #000;
}

.footer__text{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 90%;
    color: #fff;
    font-family: Exo 2;
    font-weight: 300;
    font-size: 10px;
    line-height: normal;
    margin: 25px 0;
}

.line{
    color: #fff;
    width: 80%;
    margin-top: 25px;
}

.text__scroll__wrapper{
    overflow-x: hidden;
    width: 100vw;
}

.text__scroll{
    color: #fff;
    white-space: nowrap;
    animation: animate 10s linear infinite;
    
    margin: 20px 0;
    font-style: normal;
    font-weight: lighter;
    font-size: 125px;
    line-height: 146px;
    letter-spacing: 0.1em;
}

.text__scroll >span{
    -webkit-text-stroke: 1px #fff;
    color: transparent;
}

@keyframes animate{
    0%{
        transform: translateX(100%);
    }
    100%{
        transform: translateX(-100%);
    }
}

.circle__slctr__wrapper{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.circle__slctr{
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    color: #fff;
    width: 150px;
    height: 150px;
    border-radius: 100px;
}

.lft__cir{
    background-color: transparent;
    border: 1px solid #fff;
}

.cntr__cir{
    color: #000 !important;
    background-color: #76FF76;
    border: 2px solid #000;
}

.rgt__cir{
    background-image: url('../Assets/.footer__bg.jpg.icloud');
    background-position: center;
    background-size: cover;
    border: 2px solid #fff;
}

.addit__circle{
    color: #000 !important;
    background-color: #fff;
    border: 2px solid #000;
}

@media screen and (max-width: 726px) {
    .text_scroll{
        animation: animate 7s linear infinite;
    }
}

@media only screen and (max-width: 480px){
    .footer{
        flex-wrap: wrap;
    }
    .footer >p{
        font-weight: 300;
        padding: 10px;
    }
    .circle__slctr{
        color: #fff;
        width: 120px;
        height: 120px;
        border-radius: 100px;
        font-size: 0.7rem;
    }
}
