.home{
    background-color: #181818;
    color: #fff;
    padding-top: 50px;
}

.hero__title__Container{
    padding: 5% 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: aquamarine; */
}

.hero__titleImg{
    /* background-color: darkblue; */
    width: 50%;
}

.Hero__wrapper{
    width: 90%;
    max-width: 1000px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
}

.hero__text{
    min-width: 250px;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.hero__text>p{
    width: 90%;
    font-weight: 200;
    letter-spacing: 0.1em;
}

.hero__icon{
    padding: 20px 10px 20px 0;
}

.hero__icon:hover{
    cursor: pointer;
}

.home__hero__img{
    max-width: 550px;
    width: 50vw;
    height: auto;
    border-radius: 15px;
}

/* Button */
.hero__button {
    padding: 1.3em 3em;
    font-size: 12px;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: #000;
    background-color: #fff;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}

.hero__button:hover {
    background-color: #76FF76;
    box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
    transform: translateY(-7px);
}

.hero__button:active {
    transform: translateY(-1px);
}
/* End Button */

/* carousel */

.carousel__wrapper{
    width: 100%;
    padding: 3% 0;
    background: rgb(32, 32, 32);
}

.carousel__container {
    cursor: grab;
    overflow: hidden;
    margin: 5% 5%;
}

.carousel {
    display: flex;
}

.item {
    position: relative;
    min-height: 25rem;
    min-width: 15rem;
    padding: 10px;
}

.item img {
    width: 100%;
    max-width: 300px;
    height: 100%;
    border: 1.5px solid #76FF76;
    border-radius: 20px;
    pointer-events: none;
    object-position: center;
    object-fit: cover;
}

/* End Carousel */

.reviews__container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
    margin-top: 10%;
    padding: 3% 0;
    border-radius: 0px 20px 20px 0px;
    background-color: #76FF76;
    color: #181818;
}

.reviews__title{
    font-style: normal;
    font-weight: 400;
    font-size: 2.5rem;
    line-height: 59px;
    letter-spacing: 0.15em;
}

.review__item{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 50%;
    min-width: 500px;
    margin: 3%;
}

.review__item>img{
    width: 100px;
}

.review__item>p{
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.15em;
    width: 50%;
}


@media screen and (max-width: 726px) {
    .hero__title__Container{
        padding: 10% 0;
    }
    .Hero__wrapper{
        flex-direction: column-reverse;
    }
    .hero__text{
        min-width: 250px;
        max-width: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .home__hero__img{
        width: 65vw;
    }
    .hero__text{
        margin: 10% 0;
    }
    .hero__text>p{
        font-weight: normal;
        text-align: center;
        font-size: 0.8rem;
    }
    .hero__icon{
        padding: 30px;
    }
    .carousel__wrapper{
        padding: 8% 0;
    }
    .reviews__title{
        font-size: 2rem;
    }
    .review__item>p{
        font-size: 12px;
    }
}

@media only screen and (max-width: 480px){
    .home{
        padding-top: 65px;
    }
    .hero__title__Container{
        margin: 10% 0;
    }
    .home__hero__img{
        width: 90vw;
    }
    .hero__text>p{
        width: 80%;
        font-size: 0.7rem;
        letter-spacing: 0em;
    }
    .review__item{
        width: 90%;
        min-width: 0px;
    }
    .review__item>img{
        width: 100px;
    }
    .reviews__title{
        font-size: 1.5rem;
    }
    .review__item>p{
        font-size: 10px;
    }
}