.scheduling{
    background-color: #181818;
    color: #fff;
    padding: 50px 0;
    min-height: 50vh;
}

.form__container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin: auto;
    padding: 7% 0;
    background-color: #76FF76;
    border-radius: 30px;
}

.form__wrapper{
    display: flex;
    flex-direction: column;
    /* background-color: seagreen; */
    width: 85%;
    max-width: 700px;
    color: #000;
}

.row__item{
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 50%;
    min-width: 200px;
    margin: 10% auto;
}

input[type=text]{
    width: 100%;
    height: 50px;
    font-size: 1rem;
    margin-top: 15px;
    background-color: transparent;
    outline: none;
    border: 0.5px solid #000;
    border-radius: 10px;
}

textarea{
    width: 100%;
    height: 5vh;
    border: 0.5px solid #000;
    border-radius: 10px;
    background-color: transparent;
    outline: none;
    resize: none;
    padding: 30px 0px;
}

/* Button */
.form__button {
    padding: 1.3em 3em;
    font-size: 12px;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: #000;
    background-color: #fff;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}

.form__button:hover {
    background-color: #000;
    color: #fff;
    box-shadow: 0px 15px 60px #000;
    transform: translateY(-7px);
}

.form__button:active {
    transform: translateY(-1px);
}
/* End Button */

/* submit success */
.submitSuccess__wrapper{
    width: 70%;
    color: #000;
    text-decoration: none;
}

.submitSuccess_title{
    font-size: 2.5vw;
    margin: 5% 0;
}

.email__link{
    font-size: 1.5rem;
    color: #000;
}

@media screen and (max-width: 726px) {
    .form__container{
        width: 80%;
    }
    .form__wrapper{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .row__item{
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 65%;
    }
    .submitSuccess_title{
        font-size: 2rem;
        margin: 5% 0;
    }
    
    .email__link{
        font-size: 1rem;
        color: #000;
    }
}

@media only screen and (max-width: 480px){
    .form__container{
        width: 90%;
    }
    .form__wrapper{
        display: flex;
        flex-direction: column;
        width: 85%;
    }
    .row__item{
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 50%;
    }
}
