.faq{
    background-color: #181818;
    color: #fff;
    padding-top: 50px;
}

.hero__title__Container{
    padding: 5% 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.heroTitle{
    font-weight: 500;
    font-size: 13vw;
    line-height: 80%;
}

.question__container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 7% 0;
    background-color: #76FF76;
    border-radius: 30px 30px 0px 0px;
}

.question__wrapper{
    margin: 5% 0;
    width: 40%;
    min-width: 500px;
    color: #000;
}

    .question__wrapper>h3{
        font-weight: 400;
        font-size: 2.5rem;
        line-height: 59px;
        letter-spacing: 0.15em;
        /* margin-left: -50px; */
    }

    .question__wrapper>p{
        font-weight: 300;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.15em;
        padding: 15px 0;
    }

.smallTitle{
    font-size: 1.5rem !important;
}

.faqLine{
    border: none;
    background: #000;
    height: 1px;
    width: 75%;
    margin: 10% auto;
}

.video__container{
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: center;
    padding: 5% 10%;
}

@media screen and (max-width: 726px) {
    .video__container{
        flex-wrap: wrap;
    }
    .question__wrapper>h3{
        font-size: 2rem;
    }
    .question__wrapper>p{
        font-size: 12px;
    }
}

@media only screen and (max-width: 480px){
    .question__container{
        margin-bottom: 10%;
    }
    .video__container{
        display: flex;
        padding: 0% 10%;
    }
    .question__wrapper{
        width: 75%;
        min-width: 0px;
    }
    .question__wrapper>h3{
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 59px;
        letter-spacing: 0.15em;
    }
    .question__wrapper>p{
        font-weight: 300;
        font-size: 10px;
        line-height: 18px;
        letter-spacing: 0.15em;
        padding: 15px 0;
    }
    .smallTitle{
        font-size: 1rem !important;
    }
}
