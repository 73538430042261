.navbar{
    position: fixed;
    background-color: #181818;
    display: flex;
    width: 100%;
    z-index: 1;
}

.nav__hamburger{
    width: 30px;
    padding: 20px 50px;
}

.navbar__menu{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.navbar__menu__top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.navbar__logo{
    width: 30px;
    padding: 20px 50px;
}

#hideIcon{
    display: none;
}

#displayIcon{
    color: white;
    display: flex;
}

.navbar__icon{
    background-color: transparent;
    border: transparent;
}

.nav__menu__inactive{
    display: none;
}

.nav__menu__active{
    z-index: 1;
    position: fixed;
    background-color: #181818;

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.menu__item{
    margin: 5% auto;
    text-align: center;
}

.menu__item:hover{
    border-bottom: 3px solid #fff;
    transition: ease-in 0.3s;
}

.menu__item:active { 
    border-bottom: 5px solid #fff;
}

.navlinks__wrapper{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.nav__links{
    text-align: center;
    color: #fff;
    text-decoration: none;
    letter-spacing: 2px;
    font-family: 'Libre Caslon Display', serif;
    font-size: 2.5rem;
    font-weight: 400;
}

@media only screen and (max-width: 780px){
    .navbar__menu{
        /* padding: 20px 40px; */
    }
    .navbar__logo{
        width: 22px;
    }
    .navlinks__wrapper{
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
}


@media only screen and (max-width: 480px){
    .navbar__menu{
        padding: 20px 0px;
    }
    .nav__links{
        font-size: 2.3rem;
        font-weight: 300;
    }
    .nav__menu__active{
        padding: 0% 0;
        justify-content: space-evenly;
    }
    .nav__hamburger{
        padding: 0px 50px;
    }
    .navbar__logo{
        padding: 0px 50px;
    }
    .navlinks__wrapper{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
}
